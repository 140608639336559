<template>
    <form @submit.prevent="submit">
      <loading-overlay
        :active="Loading"
        :is-full-page="true"
        loader="bars"
      />
      <CModalExtended
        :title="title"
        color="dark"
        size="lg"
        :show.sync="modalActive"
        :closeOnBackdrop="false"
        @update:show="resetForm"
      >

        <CRow>
            <!-- First column -->
            <CCol sm="6" lg="6">
                <CRow> 
                    <CCol sm="12" lg="12"> 
                        <CInput
                          v-uppercase
                          :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-6'}"
                          :label="$t('label.name')"
                          addLabelClasses="required text-right"
                          :placeholder="$t('label.blockName')"
                          :maxLength="5"
                          v-model="$v.blocksBays.BlockAlias.$model"
                          :is-valid="hasError($v.blocksBays.BlockAlias)"
                          :invalid-feedback="errorMessage($v.blocksBays.BlockAlias)"
                        >
                        </CInput>
                    </CCol>
                    <CCol sm="12" lg="12"> 
                        <CInput
                          v-uppercase
                          :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-6'}"
                          :label="$t('label.bays')"
                          addLabelClasses="required text-right"
                          :placeholder="$t('label.bays')"
                          v-model="$v.blocksBays.BayQuantity.$model"
                          :is-valid="hasError($v.blocksBays.BayQuantity)"
                          :invalid-feedback="errorMessage($v.blocksBays.BayQuantity)"
                        >
                        </CInput>
                    </CCol>
                    <CCol sm="12" lg="12"> 
                        <CSelect
                          v-uppercase
                          :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-6'}"
                          :options="heightOptions"
                          :placeholder="$t('label.select')"
                          :label="$t('label.height_')"
                          addLabelClasses="required text-right"
                          maxlength="100"
                          v-model="$v.blocksBays.Height.$model"
                          :is-valid="hasError($v.blocksBays.Height)"
                          :invalid-feedback="errorMessage($v.blocksBays.Height)"
                        >
                        </CSelect>
                    </CCol>
                </CRow>
            </CCol>
            <!-- Second column -->
            <CCol sm="6" lg="6">
                <CRow>
                    <CCol sm="12" lg="12"> 
                        <CSelect
                          v-uppercase
                          :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-6'}"
                          :options="SudClassificationOptions"
                          :label="$t('label.subclassification')"
                          addLabelClasses="text-right"
                          v-model.trim="$v.blocksBays.YardSubClasificationId.$model"
                          :is-valid="hasError($v.blocksBays.YardSubClasificationId)"
                          :invalid-feedback="errorMessage($v.blocksBays.YardSubClasificationId)"
                        >
                        </CSelect>
                    </CCol>
                    <CCol sm="12" lg="12"> 
                        <CInput
                          v-uppercase
                          :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-6'}"
                          :label="$t('label.stack')"
                          addLabelClasses="required text-right"
                          :placeholder="$t('label.stack')"
                          v-model="$v.blocksBays.StackQuantity.$model"
                          :is-valid="hasError($v.blocksBays.StackQuantity)"
                          
                          :invalid-feedback="errorMessage($v.blocksBays.StackQuantity)"
                        >
                        </CInput>
                    </CCol>
                    <CCol sm="12" lg="12"> 
                        <CSelect
                          :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-6'}"
                          :label="$t('label.blockStatus')"
                          :options="YardBlockStatusOptions"
                          addLabelClasses="required text-right"
                          :placeholder="$t('label.select')"
                          maxlength="100"
                          v-model="$v.blocksBays.YardBlockStatus.$model"
                          :is-valid="hasError($v.blocksBays.YardBlockStatus)"
                          :invalid-feedback="errorMessage($v.blocksBays.YardBlockStatus)"
                          @change="filterStatusBlock($event.target.value)"
                        >
                        </CSelect>
                    </CCol>
                </CRow>
            </CCol>
             <!-- Status -->
            <CCol sm="12" lg="12">
                <CRow>
                    <CCol sm="12" lg="6" v-if="edit">
                        <CSelect
                        :options="statusOptions"
                        :value.sync="blocksBays.Status"
                        :label="$t('label.status')"
                        :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-6'}"
                        addLabelClasses="required text-right"
                        :is-valid="statusSelectColor"
                        @change="filterStatus($event.target.value)"
                        />
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <template #footer>
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            :disabled="isSubmit"
            @click.stop="edit ? statusConfirmation(BlockBaysItems.FgActYardBlock, blocksBays.Status, submit) : submit()"
          >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
          <CButton
            square
            color="wipe"
            class="d-flex align-items-center"
            @click="resetForm"
          >
            <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
          </CButton>
      </template>
      </CModalExtended>
    </form>
  </template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from "vuex";
import { FormBlocksBays } from '@/_validations/master-yards/MasterOfYards';
  
function data() {
    return {
    isSubmit: false,
    modalActive: false,
    Loading: false,
    blocksBays: {
      BlockAlias: '',
      Height: '',
      StackQuantity:'',
      BayQuantity:'',
      YardSubClasificationId: '',
      YardBlockStatus: '',
      YardBlockId: '',
      Status: 0
    },
    SudClassification: [],
    YardBlockStatusItems: []
    };
}
//methods

function submit() {
    try {
      this.isSubmit = true;
      this.Loading = true;
      this.$v.$touch();
      if (this.$v.$error) {
        this.isSubmit = false;
        this.Loading = false;
        throw this.$t('label.errorsPleaseCheck');
      }
      let YardBlockJson = this.edit ? {
          YardBlockId: this.blocksBays.YardBlockId,
          YardId: this.getYardId,
          YardSubClasificationId: this.blocksBays.YardSubClasificationId,
          BlockAlias: this.blocksBays.BlockAlias,
          BayQuantity: this.blocksBays.BayQuantity,
          StackQuantity: this.blocksBays.StackQuantity,
          Height: this.blocksBays.Height,
          YardBlockStatus: this.blocksBays.YardBlockStatus,
          Status: this.blocksBays.Status,
        }:{
          YardId: this.getYardId,
          YardSubClasificationId: this.blocksBays.YardSubClasificationId,
          BlockAlias: this.blocksBays.BlockAlias,
          BayQuantity: this.blocksBays.BayQuantity,
          StackQuantity: this.blocksBays.StackQuantity,
          Height: this.blocksBays.Height,
          YardBlockStatus: this.blocksBays.YardBlockStatus,
        };
      
      let metodo = this.edit ? 'PUT':'POST';
      let ruta = this.edit ? 'YardBlock-update' : 'YardBlock-insert';
  
      this.$http.ejecutar(metodo, ruta, YardBlockJson, { root: 'YardBlockJson' })
        .then(response => {
          this.$emit('submited');
          this.getYards();
          this.modalActive = false;
          this.$notify({
            group: 'container',
            title: '¡Exito!',
            text: response.data.data[0].Response,
            type: "success"
          });
          this.resetForm();
        }).catch(err => {
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
          });
        }).then(() => {
          this.isSubmit = false;
          this.Loading = false;
        });
    } catch (error) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: error,
        type: "error"
      });
    }
    
}
async function getListApis() {
  this.Loading = true;
  let peticiones =  
    [
      this.$http.ejecutar("GET", "YardSubClasification-list-by-YardId", {YardId: this.getYardId}),
      this.$http.ejecutar("GET", "YardBlockStatus-list"),
    ];
  Promise.all(peticiones)
    .then((responses) => {
      this.SudClassification = responses[0].data.data;
      this.YardBlockStatusItems = responses[1].data.data;
      this.Loading = false;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

function getYards() {
  this.Loading = true;
  this.$http
    .get('Yard-list',{ CompanyBranchId: this.$store.state.auth.branch.CompanyBranchId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.lenght != 0){
        let yardItem = listado.find((item)=> item.YardId === this.$store.state.yard.yardSelected.YardId);

        if(yardItem)
          this.$store.state.yard.yardSelected = yardItem;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

function statusSelectColor() {
   return this.blocksBays.Status === 1;
}
  
function getData(val) {
  this.blocksBays.YardBlockId = val.YardBlockId,
  this.blocksBays.YardSubClasificationId = val.YardSubclasificationId,
  this.blocksBays.BlockAlias = val.BlockAlias,
  this.blocksBays.BayQuantity = val.BayQuantity,
  this.blocksBays.StackQuantity = val.StackQuantity,
  this.blocksBays.Height = val.Height,
  this.blocksBays.YardBlockStatus = val.YardBlockStatus,
  this.blocksBays.Status = val.FgActYardBlock?1:0;

  this.$v.$touch();
}
function resetForm() {
  this.blocksBays.YardBlockId = '',
  this.blocksBays.YardSubClasificationId = '',
  this.blocksBays.BlockAlias = '',
  this.blocksBays.BayQuantity = '',
  this.blocksBays.StackQuantity = '',
  this.blocksBays.Height = '',
  this.blocksBays.YardBlockStatus = '',
  this.blocksBays.Status = 0;
  this.SudClassification = [];
  this.YardBlockStatusItems = [];

  this.$emit('close');
  this.$v.$reset();
}

function filterStatusBlock(event) {
  this.blocksBays.YardBlockStatus = event;
  
}

function filterStatus(event) {
  this.blocksBays.Status = event;
  
}

//computed
function SudClassificationOptions(){
  if(this.SudClassification.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.SudClassification.map(function(e){
      if (e.FgActYardSubClasi) {
        chart.push({
          value: e.YardSubClasificationId, 
          label: e.YardSubClasificationName,  
        })   
      }     
    })
    return chart;
  }
}

function YardBlockStatusOptions(){
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.YardBlockStatusItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.YardBlockStatusItems.map(function(e){
      chart.push({
        value: e.YardBlockStatus, 
        label: e[`YardBlockStatusDs${_lang}`]   
      })    
    })
    return chart;
  }
}

function getYardId() {
    return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
}

function heightOptions() {
  return[
    { value:'',label: this.$t('label.select')},
    { value:1,label: ' 1'},
    { value:2,label: ' 2'},
    { value:3,label: ' 3'},
    { value:4,label: ' 4'},
    { value:5,label: ' 5'},
  ]       
}

export default {
    name: 'blocksBays-modal',
    props: {modal: Boolean, edit: Boolean, BlockBaysItems: Object, title: String},
    data,
    validations(){ return FormBlocksBays() },
    components: {},
    mixins: [
      ModalMixin,
    ],
    directives: UpperCase,
    methods: {
      statusSelectColor,
      getData,
      submit,
      getListApis,
      resetForm,
      filterStatusBlock,
      filterStatus,
      getYards
    },
    computed: {
      SudClassificationOptions,
      YardBlockStatusOptions,
      getYardId,
      heightOptions,
      ...mapState({
        yardSelected: state => state.yard.yardSelected
      }),
    },
    watch: {
      modal: function (val) {
        this.modalActive = val;
        if(val){
          this.getListApis();
          if (this.edit) {
            this.getData(this.BlockBaysItems);
          }
        }
      },
    },
   
  };
  </script>